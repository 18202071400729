
    .title-separator {
        position: relative;
        display: flex;
        justify-content: center;
        width: 100%;

        .content {
            position: relative;
            z-index: 2;
        }
        .content-text {
            position: relative;
            display: inline-block;
            padding: 12px;
            background: var(--global-background);
            z-index: 2;
        }

        .line {
            content: ' ';
            position: relative;
            z-index: 1;
            flex: 1px;
            flex-grow: 900;
            height: 1px;
            background: var(--global-background);
            &--after {

            }

            &--before {

            }
        }

        .content {
            color: var(--global-background);
            background: transparent;
            flex: 100%;
            width: 100%;
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            padding-left: 0;
            padding-right: 0;

            .content-text {
                flex: auto;
                background: transparent;
            }
        }
    }
